import { NavbarConfig } from "@energylab/shared-components/constants/config/navbarConfig";

export const navbarConfig: NavbarConfig = {
    fullWidth: false,
    navbarItems: [
        {
            id: "events",
            translationKey: "menuItem.events",
            redirectTo: { path: "events" }
        },
        {
            id: "about",
            translationKey: "menuItem.about",
            redirectTo: { path: "about" }
        }
    ]
};
