/* eslint-disable global-require */
import { footerConfig } from "@energylab/shared-components/constants/config/footerConfig";
import { contactConfig } from "@energylab/shared-components/constants/config/contactConfig";
import { galleryConfig } from "@energylab/shared-components/constants/config/galleryConfig";
import { resetPasswordConfig } from "@energylab/shared-components/constants/config/resetPasswordConfig";
import { whiteListSignUpConfig } from "@energylab/shared-components/constants/config/whiteListSignUpConfig";
import { nationalities } from "@energylab/shared-components/constants/nationalities";
import { ConfigReducerState } from "@energylab/shared-components/redux/reducers/configReducer";
import { getBrowserLocale } from "@energylab/shared-components/utils/browserLocale";
import { getRoutes } from "@energylab/shared-components/routes";
import * as environment from "environment";
import { defaultLocale, locales } from "./localesConfig";
import { profileConfig } from "./profileConfig";
import { registerStructure } from "./registerConfig";
import { REDIRECTS, ROUTES } from "./routes";
import { navbarConfig } from "./constants/config/navbarConfig";

// TODO: Change company logo
export const companyLogo = "https://res.cloudinary.com/energylab/image/upload/v1645012002/AB%20InBev/AB_Inbev_New_Logo.png";

export const config: ConfigReducerState = {
    mustAcceptPolicies: ["privacy", "disclaimer", "cookyPolicy"],
    navbarConfig,
    profileConfig,
    registerConfig: {
        backgroundImage: "https://res.cloudinary.com/energylab/image/upload/v1642776513/abinbev/ABinbevBackground.jpg",
        disableColorOverlay: true,
        requireEmailValidation: false,
        structure: registerStructure
    },
    footerConfig: {
        ...footerConfig,
        links: ["privacy", "disclaimer", "contact"],
        enlabLogo: undefined,
        companyLogo: "https://res.cloudinary.com/energylab/image/upload/v1640105370/AB%20InBev/flavicon_ABI.svg"
    },
    resetPasswordConfig,
    galleryConfig,
    ambassadorSettingsConfig: {
        enableRelive: false,
        enableComments: true
    },
    socialConfig: {
        disableInstagram: false,
        disableTwitter: false,
        disableFacebook: false,
        disableLinkedIn: false,
        disableOnChallenCard: false,
        hideFacebookOnCommunity: false,
        hideTwitterOnCommunity: false,
        hideInstagramOnCommunity: false,
        hideLinkedInOnCommunity: true
    },
    dashboardPlacesConfig: {
        articles: [],
        benefits: [],
        challenges: [],
        events: ["events_header"],
        gallery: [],
        homePage: [],
        leaderBoard: [],
        mySpace: [],
        mySpaceActivities: [],
        profile: [],
        webShop: [],
        contact: []
    },
    ambassadorImage: "",
    auth: {
        facebookRedirect: environment.auth.facebook,
        googleRedirect: environment.auth.google,
        anonymousToken: environment.anonymousToken
    },
    commentsConfig: {
        disabled: false,
        autoShowSubComments: false,
        autoShowInput: false
    },
    eventsConfig: {
        defaultView: "list",
        viewOptions: []
    },
    contentConfig: {
        showAuthor: true,
        useThemes: true,
        previewType: "card",
        enablePageBanner: true,
        pageBannerImage: "https://res.cloudinary.com/energylab/image/upload/v1561558218/generic-v2/bannerimage.jpg",
        pageBannerOverlay: true,
        alignment: "left"
    },
    contactConfig: {
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1561723053/generic-v2/contactbg.jpg",
        subjectOptions: [
            { key: "energylab", value: "abinbev@energylab.be" },
            { key: "internal", value: "annick.bonazzi@ab-inbev.com" }
        ],
        siteKey: contactConfig.siteKey
    },
    companyLogo,
    companyName: "ABI",
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    coinsConfig: {
        enabled: false,
        coinName: "coin"
    },
    likesConfig: {
        disabled: false
    },
    webshopConfig: {
        enabled: false,
        shopName: ""
    },
    notificationsConfig: {
        enabled: false
    },
    providers: [],
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    routeConfig: {
        locales,
        defaultLocale,
        routes: {
            private: getRoutes(ROUTES)
        },
        redirects: REDIRECTS,
        hideNavigationWhenLoggedOut: true
    },
    nationalities,
    selectedLanguage: getBrowserLocale(defaultLocale, locales),
    seo: {
        pageTitle: "ABI",
        title: {
            nl: "ABI",
            fr: "ABI",
            en: "ABI"
        },
        description: {
            nl: "",
            fr: "",
            en: ""
        },
        image: "",
        site: ""
    },
    uploadPreset: "abinbev",
    newUsersConfig: {
        enabled: false,
        hasListOfNewUsers: false
    },
    leaderboardConfig: {
        pageSize: 10
    },
    socialShareConfig: {
        enabled: true,
        copyClipboardEnabled: false,
        shareWithEmail: {
            enabled: false
        }
    },
    whiteListSignUpConfig: {
        enabled: false,
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1561723053/generic-v2/contactbg.jpg",
        subjectOptions: [],
        siteKey: whiteListSignUpConfig.siteKey
    },
    mySpace: {
        menuItems: [
            {
                iconName: "sideslier navIcon",
                translationKey: "settings",
                pathToLocaleId: "profile"
            }
        ]
    }
};
