import { minYearOld, password } from "environment";
import * as React from "react";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { nationalities } from "@energylab/shared-components/constants/nationalities";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";
import { compareToFirstPasswordCreator } from "@energylab/shared-components/utils/compareToFirstPassword";
import { emailWhitelistedCreator } from "@energylab/shared-components/utils/emailWhitelistedCreator";
import { getPasswordRules } from "@energylab/shared-components/utils/passwordRule";
import { SEX } from "@energylab/shared-components/utils/sex";
import { FormElement, FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { getBrowserLocale } from "@energylab/shared-components/utils/browserLocale";
import { locales, defaultLocale } from "./localesConfig";

export const location: FormElement = {
    title: "form.location",
    type: "select",
    property: "location",
    required: true,
    options: [
        "leuvenSite-BEL",
        "leuvenGHQ-BEL",
        "jupille-BEL",
        "hoegaarden-BEL",
        "belle-Vue-BEL",
        "anderlecht-BEL",
        "ans-BEL",
        "antwerpen-BEL",
        "dePinte-BEL",
        "hasselt-BEL",
        "jabbeke-BEL",
        "jumet-BEL",
        "breda-NL",
        "dommelen-NL",
        "arcen-NL",
        "diekirch-LUX",
        "paris-FRA",
        "lille-FRA",
        "other"
    ].map((value) => ({
        title: <FormatMessage id={`location.${value}`} />,
        value
    }))
};

export const language: FormElement = {
    title: "form.locale",
    type: "select",
    property: "locale",
    required: true,
    defaultValue: getBrowserLocale(defaultLocale, locales),
    options: locales.map(locale => ({
        title: <FormatMessage id={`locale.${locale}`} />,
        value: locale
    }))
};

export const registerStructure: FormStructureScheme = {
    type: "steps",
    steps: [
        {
            title: "register.basicStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.email",
                    type: "text",
                    property: "email",
                    validationRules: [
                        {
                            type: "email",
                            message: <FormatMessage id="auth.error.invalidEmail" />
                        },
                        {
                            validatorBuilder: emailWhitelistedCreator,
                            message: <FormatMessage id="error.emailNotWhitelisted" />
                        }
                    ],
                    required: true
                },
                {
                    title: "form.password",
                    type: "password",
                    property: "password",
                    validationRules: getPasswordRules(password)
                },
                {
                    title: "register.passwordInfo",
                    type: "p",
                    property: "passwordInfo",
                    hide: true
                },
                {
                    title: "form.confirmPassword",
                    type: "password",
                    property: "passwordConfirm",
                    validationRules: [
                        {
                            validatorBuilder: compareToFirstPasswordCreator,
                            message: <FormatMessage id="auth.error.notEqualPassword" />
                        }
                    ],
                    required: true
                },
                {
                    title: "form.companyCodeIntro",
                    type: "p",
                    property: "companyCodeIntro",
                    hide: true
                },
                {
                    title: "form.companyCode",
                    type: "text",
                    property: "companyCode",
                    hide: true
                },
                {
                    title: "form.supportMail",
                    type: "p",
                    property: "supportMail",
                    hide: true
                }
            ]
        },
        {
            title: "register.profilePoliciesStepTitle",
            status: "current",
            icon: "",
            formElements: []
        },
        {
            title: "register.profileStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "form.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                },
                {
                    title: "form.sex",
                    type: "radio",
                    options: [
                        { title: <FormatMessage id="male" />, value: SEX.male },
                        { title: <FormatMessage id="female" />, value: SEX.female },
                        { title: <FormatMessage id="intersexual" />, value: SEX.other }
                    ],
                    property: "sex",
                    required: true
                },
                {
                    title: "form.birthDate",
                    type: "date",
                    property: "birthDate",
                    required: true,
                    validationRules: [
                        {
                            validatorBuilder: checkMinBirthDateCreator(minYearOld)
                        }
                    ]
                },
                {
                    title: "form.nationality",
                    type: "select",
                    options: nationalities.map(nat => (
                        {
                            title: <FormatMessage id={`nationality.${nat.code}`} />,
                            value: nat.code
                        }
                    )),
                    property: "nationality",
                    required: true
                },
                location,
                language
            ]
        },
        {
            title: "register.profileStepExtraTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.avatar",
                    type: "uploadAvatar",
                    property: "avatar"
                },
                {
                    title: "form.useNickname",
                    type: "checkbox",
                    property: "useNickname"
                },
                {
                    title: "form.useNickname",
                    type: "text",
                    property: "nickname",
                    showWhen: {
                        property: "useNickname",
                        valueIs: true
                    },
                    required: true
                }
            ]
        }
    ]
};
