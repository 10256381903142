import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { ProfileConfig } from "@energylab/shared-components/constants/config/profileConfig";
import { nationalities } from "@energylab/shared-components/constants/nationalities";
import * as React from "react";
import * as environment from "environment";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";
import { sex } from "./sex";
import { language, location } from "./registerConfig";

export const profileStructure: FormStructureScheme = {
    type: "form",
    formElements: [
        {
            type: "row",
            property: "names",
            formElements: [
                {
                    title: "auth.fields.givenName",
                    placeholder: "auth.fields.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "auth.fields.familyName",
                    placeholder: "auth.fields.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                }
            ]
        },
        {
            title: "auth.fields.birthDate",
            type: "date",
            property: "birthDate",
            required: true,
            disabled: false,
            validationRules: [
                {
                    validatorBuilder: checkMinBirthDateCreator(environment.minYearOld)
                }
            ]
        },
        {
            title: "auth.fields.sex",
            type: "radio",
            options: sex,
            property: "sex",
            required: true
        },
        {
            title: "form.nationality",
            type: "select",
            options: nationalities.map(nat => (
                {
                    title: <FormatMessage id={`nationality.${nat.code}`} />,
                    value: nat.code
                }
            )),
            property: "nationality",
            required: true
        },
        location,
        language
    ]
};

export const profileConfig: ProfileConfig = {
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "sex",
        "nationality",
        "location",
        "locale"
    ],
    structure: profileStructure
};
