import { RedirectType, RouteType } from "@energylab/shared-components/routes/routes";
import { LocaleLink } from "@energylab/shared-components/components/UI/localeLink/localeLink"

export const ROUTES = [
    {
        element: <LocaleLink
            redirect
            replace
            to="events"
        />,
        path: "dashboard"
    }
];

export const REDIRECTS: RedirectType[] = [];
